const baseChartOption = {
  color: ["#7F8AF2"],
  grid: {
    left: "0",
    right: "40px",
    top: "20px",
    bottom: "0px",
    containLabel: true,
  },
  xAxis: {
    show: false,
    type: "value",
  },
  dataZoom: [
    {
      brushSelect: false, //不要让他有+号，即范围筛选
      // 设置滚动条的隐藏或显示
      show: true,
      // 设置类型
      type: "slider",
      // 设置背景颜色
      backgroundColor: "white",
      // 设置选中范围的填充颜色
      fillerColor: "#7F8AF2",
      // 设置边框颜色
      borderColor: "#7F8AF2",
      // 是否显示detail，即拖拽时候显示详细数值信息
      showDetail: false,
      // 数据窗口范围的起始数值
      startValue: 0,
      // 数据窗口范围的结束数值（一页显示多少条数据）
      endValue: 10,
      // 控制哪个轴，如果是number表示控制一个轴，
      // 如果是Array表示控制多个轴。此处控制第二根轴
      yAxisIndex: [0, 1],
      // empty：当前数据窗口外的数据，被设置为空。
      // 即不会影响其他轴的数据范围
      filterMode: "empty",
      // 滚动条高度
      width: 8,
      // 滚动条显示位置
      height: "98%",
      // 距离右边
      right: 3,
      // 控制手柄的尺寸
      handleSize: 0,
      // 是否锁定选择区域（或叫做数据窗口）的大小
      // zoomLoxk: true,
      // 组件离容器上侧的距离
      // 如果top的值为'top', 'middle', 'bottom'，组件会根据相应的位置自动对齐
      top: "top",
    },
    {
      // 没有下面这块的话，只能拖动滚动条，
      // 鼠标滚轮在区域内不能控制外部滚动条
      type: "inside",
      // 控制哪个轴，如果是number表示控制一个轴，
      // 如果是Array表示控制多个轴。此处控制第二根轴
      yAxisIndex: [0, 1],
      // 滚轮是否触发缩放
      zoomOnMouseWheel: false,
      // 鼠标移动能否触发平移
      moveOnMouseMove: true,
      // 鼠标滚轮能否触发平移
      moveOnMouseWheel: true,
    },
  ],
};
const baseYAxis = {
  show: true,
  type: "category",
  axisTick: false,
  axisLine: false,
  inverse: "true", // 排序,
};
const baseYAxisLabel = {
  show: true,
  inside: true,
  interval: 0, //横轴信息全部显
  splitNumber: 1,
  color: "#333333",
  verticalAlign: "bottom",
  fontSize: 16,
  align: "left",
  padding: [0, 0, 8, -5],
  rich: {
    a: {
      color: "#333",
      width: 30,
      height: 30,
      align: "center",
      fontSize: 16,
      lineHeight: 30,
      borderRadius: 2,
      borderColor: "#7F8AF2",
      borderWidth: 2,
      shadowColor: "#7F8AF2",
      shadowBlur: 10,
    },
    b: {
      color: "#333",
      width: 25,
      height: 25,
      align: "center",
      fontSize: 14,
      lineHeight: 25,
      borderRadius: 2,
      borderColor: "red",
      borderWidth: 2,
      shadowColor: "red",
      shadowBlur: 10,
    },
  },
};
const sourceCustomer = [
  "B仔",
  "玲玲屋李太",
  "黄少玲",
  "冰仔",
  "周小姐",
  "栀子花开",
  "肥妹",
  "宝安谢小姐7651",
  "蓝东平",
  "素红",
  "方海A139",
  "胡小姐",
  "深圳布吉巧手订珠厂",
  "叶小姐（刘小姐微信号下单）",
  "西华宫",
  "陈英",
  "金辉",
  "雯雯",
  "东东妈",
  "许秀娴",
  "秋秋",
  "3号",
  "罗学玲",
  "天水围黄小姐",
  "花园街冯生",
  "郑太",
  "袁萍",
  "婷婷",
  "空白2",
  "空白1",
  "英子",
  "幸子",
  "空白3",
  "空白4",
  "空白5",
  "周太",
  "高小姐",
  "老乡靓女",
  "阿红",
  "陈小姐",
  "刘小姐",
  "蔡小姐",
  "友名堂陈小姐",
  "古伟香",
  "S店",
  "肥仔老婆",
  "珠姐",
  "黄秀梅",
  "黄华",
  "大龙老婆",
  "欧轩伲",
  "张文英新白马",
  "郑小姐 60770730",
  "李小姐91081211",
  "商业城3277",
  "申海英",
  "上合桥",
  "陈丽丽",
  "林国坚",
  "曾小姐51605422",
  "香港蔡小姐",
  "蔡雪华",
  "817",
  "芳姐",
  "温小姐",
  "段小姐",
  "旺角林小姐",
  "澳门林小姐",
  "肥仔",
  "陈永锦（梁子琪）",
  "黄小姐",
  "杨珍46",
  "靓仔吴",
  "林太",
  "蔡小姐新都",
  "叶小姐67573653",
  "宝灵街何小姐",
  "太子黄小姐",
  "E15",
  "陈晓萍",
  "卓晓娟",
  "曾友兰63584158",
  "元朗陈小姐",
  "周小姐62907855",
  "梁小姐94299955",
  "赵小姐尖沙咀",
  "谢育霞",
  "4045小燕",
  "阿叶1288",
  "小亚米米兰亚",
  "曾小姐65777664",
  "阿彬",
  "张小姐",
  "蔡小姐慈云山",
  "叶小姐92294311",
  "刘小姐68952080",
  "何少玲",
  "陈中华",
  "欧小姐",
  "杨小姐（阿叔收）",
  "贵族童鞋",
  "莎莎",
  "欧丽",
  "大龙",
  "范小姐",
  "陈皮",
  "许太",
  "蓝田钟小姐",
  "黄小姐 屯门盈丰",
  "林小姐90235546",
  "九龙城297",
  "蔡枫林",
  "维妮",
  "竹玲屋",
  "许楚端",
  "金牌",
  "日韩美妆（杨小姐）",
  "香港铜锣湾蔡小姐",
  "林国坚168",
  "观塘叶小姐",
  "博罗梁小姐",
  "桌艺贸易有限公司",
  "元朗曾小姐",
  "林国坚145",
  "张小姐 96519814",
  "玲子",
  "陈锦华 98614089",
  "秋娟",
  "买手杨",
  "冰冰饰品",
  "阿华48号",
  "樱姐",
  "非凡阿燕",
  "陈少莲",
  "谢太 尚德",
  "Y单",
  "江萍",
  "梁小姐",
  "KK小姐",
  "赖小姐 妹妹",
  "黄丽丽",
  "三号",
  "土瓜湾吴小姐",
  "友诚时装曾太",
  "黄小姐汕尾FLY",
  "小燕4045",
  "叶彩娣",
  "晨晨吕小姐",
  "朱绍爱",
  "红莉",
  "鸿雁邓小姐",
  "清远林小姐",
  "林国坚125A",
  "陈燕子",
  "吴佩霞",
  "陈小姐（零售）",
  "厦门英子",
  "朱敏",
  "郑丽芳",
  "曾小姐零售",
  "张楚悦",
  "刘平",
  "香港丽丽",
  "旺角林小姐60772587",
  "华姐",
  "黄小姐56291138",
  "林太98067318",
  "美美",
  "B型",
  "黄秋红",
  "万远香",
  "黄彩花",
  "茶枫林",
  "YOYO魏小姐62834987",
  "香港卓艺贸易有限公司",
  "黄百合",
  "关小姐",
  "A雯雯",
  "张雪映",
  "福达汽运小杨",
  "香港刘小姐67075668",
  "蔡小姐刘生",
  "霖霖廖小姐",
  "公明黄",
  "可人儿时装萧小姐",
  "黄小姐东东莞",
  "燕子刘小艳",
  "蓝紫叶小姐",
  "静蝉",
  "5号",
  "香港九龙陈小姐",
  "内在美B仔",
  "黄丽娟",
  "东莞彭小姐",
  "迷你屋翁小姐",
  "行哥",
  "谭小姐",
  "朱小姐96169626",
  "卓小姐",
  "阿婵",
  "彭小姐17727817764",
  "小苹古小姐",
  "九龙城樱姐",
  "郑惠琴",
  "林小平",
  "谢玉霞",
  "成记黄小姐",
  "旺角林小姐92799398",
  "江小姐",
  "龙岗周生义乌C2358",
  "卢小姐",
  "RW阿彬",
  "范素萍",
  "余小姐",
  "华侨李小姐",
  "香港何小姐新客",
  "梁小姐97989250",
  "MISS林小姐",
  "好好学习",
  "罗湖小刘",
  "宏仔",
  "横岗张小姐",
  "商业城3101姚小姐",
  "商业城4198",
  "徐洁",
  "东莞朱朱",
  "卢嘉玲",
  "玫丽娟娟",
  "陈丽珍",
  "洋洋湘杬",
  "何小姐早上食",
  "曾小姐63863189",
  "广西吴彤微",
  "郭小姐Alfee",
  "虎门林小姐",
  "许珍玲",
  "肖肖",
  "魅力时尚",
  "吴慧",
  "文静",
  "幸福狐狸",
  "顺其自然",
  "宝玲",
  "童装呦呦",
  "曾小姐（小宝收）",
  "汤生海讯达",
  "李翠红",
  "艾蔓婷彭小姐13715238612",
  "曾郁洋",
  "温小姐13670856662",
  "河源张小姐",
  "范小姐和李小姐阿叔收",
  "香港许太69391084",
  "赖小姐67531400",
  "小珠许小姐",
];
const customerList = sourceCustomer.map((name, index) => ({
  value: index + 1, // 从1开始自增作为ID
  name: name,
}));


export { baseChartOption, baseYAxis, baseYAxisLabel, customerList };

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <el-row :gutter="20">
              <el-col :span="12" class="alignBox"
                ><span>客户名称：</span>
                <el-select
                  style="flex: 1"
                  v-model="customerVal"
                  filterable
                  placeholder="请选择"
                  @change="selectCutomer"
                >
                  <el-option
                    v-for="customer in customerList"
                    :key="customer.name"
                    :label="customer.name"
                    :value="customer.name"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12" class="alignBox">
                <span>商品名称：</span>
                <el-input
                  style="flex: 1"
                  @change="changeInputVal"
                  placeholder="请输入内容"
                  v-model="inputVal"
                  clearable
                  :disabled="!customerVal"
                >
                </el-input
              ></el-col>
            </el-row>
          </div>
          <BaseChartCommon
            chartId="customerChart"
            :chartOption="ctrChartOption"
            height="740"
            width="100"
          />
          <!-- <div
            class="chartBox"
            id="saleChart"
            style="min-height: 760px; width: 100%"
          ></div> -->
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  saleChartOption,
  saleYAxis,
  saleYAxisLabel,
  customerList,
} from "../customerManagement/chartConfig.js";
import BaseChartCommon from "@/components/BaseChartCommon.vue";
import chartMixin from "@/components/AboutChart/chartMixin";

export default {
  components: {
    BaseChartCommon,
  },
  mixins: [chartMixin],
  data() {
    return {
      customerList,
      customerVal: "",
      classificationData: {},
      inputVal: "",
      ctrChartOption: {},

      chartData: [],
      xAxisData: [],
      seriesData: [],
    };
  },
  created() {
    // 赋值（这时你打印detailedList，就是我们要的目标数据了）
    let sourceData = [
      // ...JSON.parse(localStorage.getItem("2024-12-06")),
      // ...JSON.parse(localStorage.getItem("2024-12-07")),
      // ...JSON.parse(localStorage.getItem("2024-12-08")),
      // ...JSON.parse(localStorage.getItem("2024-12-09")),
      // ...JSON.parse(localStorage.getItem("2024-12-10")),
      // ...JSON.parse(localStorage.getItem("2024-12-11")),
      // ...JSON.parse(localStorage.getItem("2024-12-12")),
      // ...JSON.parse(localStorage.getItem("2024-12-13")),
      ...JSON.parse(localStorage.getItem("2024-12-14")),
      ...JSON.parse(localStorage.getItem("2024-12-15")),
      ...JSON.parse(localStorage.getItem("2024-12-16")),
      ...JSON.parse(localStorage.getItem("2024-12-17")),
      ...JSON.parse(localStorage.getItem("2024-12-18")),
      // ...JSON.parse(localStorage.getItem("2024-12-19")),
    ];

    this.classificationData = this.classifyData(sourceData, "customer");
  },
  mounted() {},
  methods: {
    // 1.把数据按照 客户名称，分离出来
    classifyData(list, groupId) {
      function groupBy(array, f) {
        const groups = {};
        array.forEach(function (o) {
          // const group = JSON.stringify(f(o));
          const group = f(o);
          groups[group] = groups[group] || [];
          groups[group].push(o);
        });
        return groups;
      }
      return groupBy(list, function (item) {
        return item[groupId];
      });
    },
    changeInputVal() {
      console.log("搜索", this.inputVal);
      this.handleSaleChartData();
    },
    selectCutomer() {
      console.log("选择客户", this.customerVal);
      this.inputVal = "";
      this.handleSaleChartData();
    },
    // 3.处理 sale 图表数据
    handleSaleChartData() {
      this.xAxisData = [];
      this.seriesData = [];
      this.chartData = [];
      let currentCustomerList = this.classificationData[this.customerVal];

      console.log("分组前：", this.sourceData);
      console.log("分组后：", this.classificationData);
      console.log("需要的客户数据", currentCustomerList);

      if (!currentCustomerList) {
        this.ctrChartOption = {};
        this.resetConfig();
        return;
      }
      this.chartData = this.handleChartData(
        this.inputVal,
        currentCustomerList,
        this.xAxisData,
        this.seriesData
      );
      this.generateSaleChart();
    },
    // 4.生成 sale 图表
    generateSaleChart() {
      // 基于准备好的dom，初始化echarts实例
      // var myChart = echarts.init(document.getElementById("saleChart"));
      // 绘制图表
      this.ctrChartOption = {
        ...saleChartOption,
        // 标题
        yAxis: [
          {
            ...saleYAxis,
            axisLabel: {
              ...saleYAxisLabel,
              formatter: (params) => {
                var currentIndex = this.xAxisData.indexOf(params);
                return [
                  "{a|" +
                    this.chartData[currentIndex].price +
                    "}" +
                    "  " +
                    params,
                ].join("\n");
              },
            },
            data: this.xAxisData,
          },
        ],
        series: [
          {
            // inverse: "true", // 排序
            data: this.seriesData,
            type: "bar",
            showBackground: false,
            barWidth: "10",
            itemStyle: {
              borderRadius: 10,
            },
            label: {
              normal: {
                show: true,
                position: "right",
                textStyle: {
                  color: "#333",
                },
                formatter: (params) => {
                  var index = this.xAxisData.indexOf(params.name);
                  if (this.chartData[index].tag) {
                    // console.log("特定标签", this.chartData[index].tag);
                    return (
                      params.value +
                      "" +
                      this.chartData[index].tag.slice(0, 1) +
                      this.chartData[index].price
                    );
                  } else {
                    return (
                      params.value +
                      "" +
                      this.chartData[index].unit +
                      this.chartData[index].price
                    );
                  }
                },
              },
            },
          },
        ],
      };
      // // 如果窗口大小改变，重新调整图表大小
      // (window.onresize = function () {
      //   myChart.resize();
      // });
    },
    resetConfig() {
      this.inputVal = "";
      this.customerVal = "";
      this.classificationData = {};
      this.xAxisData = [];
      this.seriesData = [];
      this.chartData = [];
    },
  },
};
</script>
<style>
.chartBox {
  overflow: auto;
}
.alignBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    font-size: 14px;
  }
}
</style>

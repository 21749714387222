<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24"
        ><el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>上传日期：</span>
            <el-date-picker
              v-model="upLoadDate"
              @change="changeUpLoadDate"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
          <el-upload
            :on-change="readExcel"
            :on-remove="removeExcel"
            :limit="999"
            class="upload-demo"
            drag
            action="#"
            :auto-upload="false"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
          <el-button
            type="primary"
            @click="saveSaleData"
            :disabled="
              this.upLoadDate && this.excelData.length > 0 ? false : true
            "
            >上传销售数据</el-button
          >
          <el-button
            type="primary"
            @click="saveSaleData('goods')"
            :disabled="this.excelData.length > 0 ? false : true"
            >上传来货数据</el-button
          >
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      upLoadDate: "",
      excelData: [],
    };
  },

  methods: {
    // 1.读取excel数据
    readExcel(e) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        this.excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        console.log("1.初次处理的excel数据", this.excelData);
      };
      reader.readAsArrayBuffer(e.raw);
    },
    // 2.存储 二次处理过的 excel 销售数据
    saveSaleData(upLoadTag) {
      let keyList = this.excelData[0];
      let valList = [...this.excelData.slice(1)];
      let transitionList = [];
      valList.forEach((vItem) => {
        let saleObj = {};
        keyList.forEach((kItem, kIndex) => {
          saleObj[kItem] = vItem[kIndex];
        });

        transitionList.push(saleObj);
      });

      // 取出销量相关数据
      let targetKeyList = [
        "shopName",
        "unit",
        "quantity",
        "price",
        "amount",
        "customer",
        "notes",
        "tag",
      ];
      let resultList = [];
      transitionList.forEach((rItem) => {
        let resultObj = {};
        targetKeyList.forEach((key) => {
          resultObj[key] = rItem[key];
          resultObj["currentDate"] = this.upLoadDate;
        });
        if (resultObj["shopName"] != undefined) {
          resultList.push(resultObj);
        }
      });

      resultList.map((mItem) => {
        switch (mItem.unit) {
          case "打":
            mItem.quantity = mItem.quantity * 12;
            mItem.tag = mItem.unit;
            mItem.unit = "双";
            // mItem.price = (mItem.price / 12).toFixed(2)*1;
            break;
          case "包/6":
            mItem.quantity = mItem.quantity * 6;
            mItem.tag = mItem.unit;
            mItem.unit = "双";
            // mItem.price = (mItem.price / 10).toFixed(2)*1;
            break;
          case "包/10":
            mItem.quantity = mItem.quantity * 10;
            mItem.tag = mItem.unit;
            mItem.unit = "双";
            // mItem.price = (mItem.price / 10).toFixed(2)*1;
            break;
          case "包/15":
            mItem.quantity = mItem.quantity * 15;
            mItem.tag = mItem.unit;
            mItem.unit = "双";
            // mItem.price = (mItem.price / 10).toFixed(2)*1;
            break;
          case "包/20":
            mItem.quantity = mItem.quantity * 20;
            mItem.tag = mItem.unit;
            mItem.unit = "双";
            // mItem.price = (mItem.price / 20).toFixed(2)*1;
            break;
          default:
            break;
        }
      });

      if (upLoadTag == "goods") {
        localStorage.setItem("来货数据", JSON.stringify(resultList));
      } else {
        localStorage.setItem(this.upLoadDate, JSON.stringify(resultList));
        console.log(
          "处理后的数据",
          JSON.parse(localStorage.getItem(this.upLoadDate))
        );
      }
    },

    removeExcel() {
      this.excelData = [];
    },
    changeUpLoadDate() {
      console.log(this.upLoadDate);
    },
  },
};
</script>
<style></style>

<template>
  <div class="bar-chart-common">
    <slot name="title"></slot>
    <!-- height < 50 ? height : 'min-height : 50px' -->
    <div
      v-if="this.chartOption.isEmty"
      :style="[styleComputed]"
      class="emty-box"
    >
      暂无数据
    </div>
    <div
      v-else
      :id="chartId"
      :style="[styleComputed]"
      class="base-echart"
    ></div>
  </div>
</template>

<script>
import * as echarts from "echarts";


// 按需引入基本模板
// import echarts from "echarts/lib/echarts";
// import "echarts/lib/chart/bar";
// import "echarts/lib/chart/pie";
// import "echarts/lib/chart/line";
// import "echarts/lib/component/legend";
// import "echarts/lib/component/title";
// import "echarts/lib/component/graphic";
// import "echarts/lib/component/radar";
// import "echarts-gl";
// import "echarts-liquidfill";

export default {
  name: "BaseChartCommon",
  props: {
    chartId: {
      type: [String],
      default: "",
      required: false,
    },
    width: {
      type: [String, Number],
      default: 100,
    },
    height: {
      type: [String, Number],
      default: 200,
    },
    chartOption: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isSelfEmty: false,
      myCharts: null,
    };
  },
  computed: {
    styleComputed() {
      let styleObj = {
        width: this.width == 100 ? this.width + "%" : this.width + "px",
        height: this.height + "px",
      };
      return styleObj;
    },
  },
  watch: {
    chartOption: {
      handler(newVal) {
        if (this.chartOption.isEmty) {
          this.myCharts && this.myCharts.dispose();
          // this.$forceUpdate();
        } else {
          this.myCharts = null;
          // console.log('更新',newVal)
          this.drawChart();
        }
      },
      // immediate: true,
      deep: true,
    },
  },
  created() {
    // this.chartId = this.chartId;
    // this.drawChart();
  },
  beforeDestroy() {
    this.myCharts && this.myCharts.dispose();
  },
  methods: {
    drawChart() {
      if (!this.chartId) {
        this.chartId = `${
          new Date().getTime() + Math.floor(Math.random() * 1000)
        }BaseBarEchart`;
      }
      this.$nextTick(() => {
        const chartDom = document.getElementById(this.chartId);
        // console.log('xxxxxxxxxxxx',chartDom)
        // chartDom.removeAttribute("_echarts_instance_");
        if (!chartDom) return;
        if (!this.myCharts) {
          const myChart = echarts.init(chartDom, "chalk", {
            height: this.height,
          });
          this.myCharts = myChart;
        }
        this.myCharts.clear();
        // console.log("chartoptions:", this.chartOption)
        this.chartOption && this.myCharts.setOption(this.chartOption);
        // this.myCharts.off();
        // this.myCharts.on("click", (params) => {
        //   console.log("点击了图表清缓存", params);
        //   this.$emit("chartClickLittle", params);
        //   this.$EventBus.$emit("chartClick", params);
        // });
        // this.echart.on("click", "xAxis.category", (params) => {
        //   this.$emit("onXClick", params);
        // });
        // this.echart.on("legendselectchanged", (params) => {
        //   this.$emit("legendChange", params, this.echart);
        // });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.base-echart {
  width: 100%;
  height: 100%;
  // min-height: 50px;
}
.emty-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import ProductList from "@/views/productManagement/productList.vue";
import DeliveryList from "@/views/deliveryManagement/deliveryList.vue";
import RedemptionOfPoints from "@/views/userManagement/redemptionOfPoints.vue";
import SalesRanking from "@/views/dataDashboard/salesRanking.vue";
import CustomerSale from "@/views/dataDashboard/customerSale.vue";
import UploadCaiZiData from "@/views/dataDashboard/uploadCaiZiData.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "dataDashboard/salesRanking",
        name: "SalesRanking",
        component: SalesRanking,
      },
      {
        path: "dataDashboard/customerSale",
        name: "customerSale",
        component: CustomerSale,
      },
      {
        path: "dataDashboard/uploadCaiZiData",
        name: "uploadCaiZiData",
        component: UploadCaiZiData,
      },
      {
        path: "productManagement/productList",
        name: "productList",
        component: ProductList,
      },
      {
        path: "deliveryManagement/deliveryList",
        name: "deliveryList",
        component: DeliveryList,
      },
      {
        path: "userManagement/redemptionOfPoints",
        name: "redemptionOfPoints",
        component: RedemptionOfPoints,
      },
     

      // { path: "*", redirect: "/productManagement/productList" },
      // {
      //   path: "c",
      //   component: (resolve) => require(["@/pages/practice/c"], resolve),
      // },
    ],
  },
  // {
  //   path: "*",
  //   redirect: "/",
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

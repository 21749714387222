<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <el-row :gutter="20">
              <el-col :span="12" class="alignBox"
                ><span>看板日期：</span>
                <el-date-picker
                  style="flex: 1"
                  @change="changeSaleDate"
                  v-model="saleDateVal"
                  type="months"
                  placeholder="选择月"
                  format="yyyy 年 MM 月"
                  value-format="yyyy-MM"
                >
                </el-date-picker
              ></el-col>
              <el-col :span="12" class="alignBox">
                <span>商品名称：</span>
                <el-input
                  style="flex: 1"
                  @change="changeSaleInputVal"
                  placeholder="请输入内容"
                  v-model="saleInputVal"
                  clearable
                >
                </el-input
              ></el-col>
            </el-row>
          </div>
          <BaseChartCommon
            chartId="saleChart"
            :chartOption="saleChartOption"
            height="740"
            width="100"
          />
        </el-card>
      </el-col>
      <el-col :span="12"
        ><el-card class="box-card">
          <div slot="header" class="clearfix">
            <el-row :gutter="20">
              <el-col :span="12" class="alignBox">
                <span>来货日期：</span>
                <el-date-picker
                  v-model="goodsDateVal"
                  @change="changeGoodsDate"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker
              ></el-col>
              <el-col :span="12" class="alignBox">
                <span>商品名称：</span>
                <el-input
                  style="flex: 1"
                  @change="changeGoodsInputVal"
                  placeholder="请输入内容"
                  v-model="goodsInputVal"
                  clearable
                >
                </el-input
              ></el-col>
            </el-row>
          </div>

          <BaseChartCommon
            chartId="goodsChart"
            :chartOption="goodsChartOption"
            height="740"
            width="100"
          />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { baseChartOption, baseYAxis, baseYAxisLabel } from "./chartConfig.js";
import chartMixin from "@/components/AboutChart/chartMixin";
import BaseChartCommon from "@/components/BaseChartCommon.vue";

export default {
  data() {
    return {
      // 销售看板
      saleDateVal: "",
      saleList: [],
      saleInputVal: "",
      saleChartData: [],
      saleChartOption: {},
      saleXAxisData: [],
      saleSeriesData: [],

      // 库存看板
      goodsDateVal: "",
      goodsList: [],
      goodsInputVal: "",
      goodsChartData: [],
      goodsChartOption: {},
      goodsXAxisData: [],
      goodsSeriesData: [],
    };
  },
  components: {
    BaseChartCommon,
  },
  mixins: [chartMixin],

  created() {
    this.saleList = [
      ...JSON.parse(localStorage.getItem("2024-12-06")),
      ...JSON.parse(localStorage.getItem("2024-12-07")),
      ...JSON.parse(localStorage.getItem("2024-12-08")),
      ...JSON.parse(localStorage.getItem("2024-12-09")),
      ...JSON.parse(localStorage.getItem("2024-12-10")),
      ...JSON.parse(localStorage.getItem("2024-12-11")),
      ...JSON.parse(localStorage.getItem("2024-12-12")),
      ...JSON.parse(localStorage.getItem("2024-12-13")),
      ...JSON.parse(localStorage.getItem("2024-12-14")),
      ...JSON.parse(localStorage.getItem("2024-12-15")),
      ...JSON.parse(localStorage.getItem("2024-12-16")),
      ...JSON.parse(localStorage.getItem("2024-12-17")),
      ...JSON.parse(localStorage.getItem("2024-12-18")),
      ...JSON.parse(localStorage.getItem("2024-12-19")),
      ...JSON.parse(localStorage.getItem("2024-12-20")),
      ...JSON.parse(localStorage.getItem("2024-12-21")),
      ...JSON.parse(localStorage.getItem("2024-12-22")),
      ...JSON.parse(localStorage.getItem("2024-12-23")),
      ...JSON.parse(localStorage.getItem("2024-12-24")),
      ...JSON.parse(localStorage.getItem("2024-12-25")),
      ...JSON.parse(localStorage.getItem("2024-12-26")),
      ...JSON.parse(localStorage.getItem("2024-12-27")),
      ...JSON.parse(localStorage.getItem("2024-12-28")),
      ...JSON.parse(localStorage.getItem("2024-12-29")),
      ...JSON.parse(localStorage.getItem("2024-12-30")),
      ...JSON.parse(localStorage.getItem("2024-12-31")),
      ...JSON.parse(localStorage.getItem("2025-01-01")),
      ...JSON.parse(localStorage.getItem("2025-01-02")),
      ...JSON.parse(localStorage.getItem("2025-01-03")),
      ...JSON.parse(localStorage.getItem("2025-01-04")),
      ...JSON.parse(localStorage.getItem("2025-01-05")),
      ...JSON.parse(localStorage.getItem("2025-01-06")),
      ...JSON.parse(localStorage.getItem("2025-01-07")),
      ...JSON.parse(localStorage.getItem("2025-01-08")),
      ...JSON.parse(localStorage.getItem("2025-01-09")),
      ...JSON.parse(localStorage.getItem("2025-01-10")),
      ...JSON.parse(localStorage.getItem("2025-01-11")),
      ...JSON.parse(localStorage.getItem("2025-01-12")),
      ...JSON.parse(localStorage.getItem("2025-01-13")),
      ...JSON.parse(localStorage.getItem("2025-01-14")),
      ...JSON.parse(localStorage.getItem("2025-01-15")),
      ...JSON.parse(localStorage.getItem("2025-01-16")),
      ...JSON.parse(localStorage.getItem("2025-01-17")),
      ...JSON.parse(localStorage.getItem("2025-01-18")),
      ...JSON.parse(localStorage.getItem("2025-01-19")),
      // ...JSON.parse(localStorage.getItem("2025-01-20")),
      ...JSON.parse(localStorage.getItem("2025-01-21")),
      ...JSON.parse(localStorage.getItem("2025-01-22")),
      ...JSON.parse(localStorage.getItem("2025-01-23")),
      ...JSON.parse(localStorage.getItem("2025-01-24")),
      ...JSON.parse(localStorage.getItem("2025-01-25")),
      ...JSON.parse(localStorage.getItem("2025-01-26")),
    ];
    console.log("1.指定日期销售看板数据", this.saleList);
  },
  mounted() {
    this.handleSaleChartData();
    this.reduceInventory();
  },
  methods: {
    // 2.处理 sale 图表数据
    handleSaleChartData() {
      this.saleXAxisData = [];
      this.saleSeriesData = [];
      this.saleChartData = [];

      this.saleChartData = this.handleChartData(
        this.saleInputVal,
        this.saleList,
        this.saleXAxisData,
        this.saleSeriesData
      );
      this.generateSaleChart();
    },
    // 3.生成 sale 图表
    generateSaleChart() {
      // 绘制图表
      this.saleChartOption = {
        ...baseChartOption,
        // 标题
        yAxis: [
          {
            ...baseYAxis,
            axisLabel: {
              ...baseYAxisLabel,
              formatter: (params) => {
                var index = this.saleXAxisData.indexOf(params);
                index = index + 1;
                return ["{a|" + index + "}" + "  " + params].join("\n");
              },
            },
            data: this.saleXAxisData,
          },
        ],
        series: [
          {
            // inverse: "true", // 排序
            data: this.saleSeriesData,
            type: "bar",
            showBackground: false,
            barWidth: "10",
            itemStyle: {
              borderRadius: 10,
            },
            label: {
              normal: {
                show: true,
                position: "right",
                textStyle: {
                  color: "#333",
                },
                formatter: (params) => {
                  var index = this.saleXAxisData.indexOf(params.name);
                  if (this.saleChartData[index].tag) {
                    // console.log("特定标签", this.saleChartData[index].tag);
                    return (
                      params.value +
                      "" +
                      this.saleChartData[index].tag.slice(0, 1)
                    );
                  } else {
                    return params.value + "" + this.saleChartData[index].unit;
                  }
                },
              },
            },
          },
        ],
      };
    },
    changeSaleDate(value) {
      console.log("value", value, this.saleDateVal);
    },
    changeSaleInputVal() {
      console.log("搜索", this.saleInputVal);
      this.handleSaleChartData();
    },


    // 库存看板 1. 扣库存
    reduceInventory() {
      let baseList = [...JSON.parse(localStorage.getItem("来货数据"))];
      let saleList = [
        // ...JSON.parse(localStorage.getItem("2024-12-06")),
        // ...JSON.parse(localStorage.getItem("2024-12-07")),
        // ...JSON.parse(localStorage.getItem("2024-12-08")),
        // ...JSON.parse(localStorage.getItem("2024-12-09")),
        // ...JSON.parse(localStorage.getItem("2024-12-10")),
        // ...JSON.parse(localStorage.getItem("2024-12-11")),
        ...JSON.parse(localStorage.getItem("2024-12-12")),
        ...JSON.parse(localStorage.getItem("2024-12-13")),
      ];
      // 相同商品进行数量累加
      baseList = baseList.reduce((acc, current) => {
        const existing = acc.find((item) => item.shopName === current.shopName);
        if (existing) {
          if (current.tag) {
            existing.tag = current.tag;
          }
          existing.quantity += current.quantity;
        } else {
          acc.push({ ...current });
        }
        return acc;
      }, []);
      baseList.forEach((bItem) => {
        saleList.forEach((sItem) => {
          if (bItem.shopName == sItem.shopName) {
            bItem.quantity -= sItem.quantity;
          }
        });
      });
      this.goodsList = baseList;

      this.handleGoodsChartData();
      console.log("1.扣完库存后的数据", baseList);
    },
    // 2. 处理 goods图表数据
    handleGoodsChartData() {
      this.goodsXAxisData = [];
      this.goodsSeriesData = [];
      this.goodsChartData = [];

      this.goodsChartData = this.handleChartData(
        this.goodsInputVal,
        this.goodsList,
        this.goodsXAxisData,
        this.goodsSeriesData
      );
      this.generateGoodsChart();
    },
    // 3. 生成 goods 图表
    generateGoodsChart() {
      // 绘制图表
      this.goodsChartOption = {
        ...baseChartOption,
        // 标题
        yAxis: [
          {
            ...baseYAxis,
            axisLabel: {
              ...baseYAxisLabel,
              formatter: (params) => {
                var index = this.goodsXAxisData.indexOf(params);
                index = index + 1;
                return ["{a|" + index + "}" + "  " + params].join("\n");
              },
            },
            data: this.goodsXAxisData,
          },
        ],
        series: [
          {
            // inverse: "true", // 排序
            data: this.goodsSeriesData,
            type: "bar",
            showBackground: false,
            barWidth: "10",
            itemStyle: {
              borderRadius: 10,
            },
            label: {
              normal: {
                show: true,
                position: "right",
                textStyle: {
                  color: "#333",
                },
                formatter: (params) => {
                  var index = this.goodsXAxisData.indexOf(params.name);
                  if (this.goodsChartData[index].tag) {
                    // console.log("特定标签", this.goodsChartData[index].tag);
                    return (
                      params.value +
                      "" +
                      this.goodsChartData[index].tag.slice(0, 1)
                    );
                  } else {
                    return params.value + "" + this.goodsChartData[index].unit;
                  }
                },
              },
            },
          },
        ],
      };
    },
    changeGoodsInputVal() {
      console.log("搜索", this.saleInputVal);
      this.reduceInventory();
    },
   
    changeGoodsDate(value){
      console.log("value", value, this.goodsDateVal);
    }
  },
};
</script>
<style>
.chartBox {
  overflow: auto;
}
.alignBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    font-size: 14px;
  }
}
</style>

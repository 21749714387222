export default {
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    handleChartData(inputVal, sourceList, xAxisData, seriesData) {
      // 筛选
      if (inputVal) {
        sourceList = sourceList.filter((fItem) => {
          // console.log("xxxx", fItem.shopName.includes(inputVal));
          return fItem.shopName.includes(inputVal);
        });
      }
      console.log("筛选出来的数组",JSON.parse(JSON.stringify(sourceList)) );
      let chartData = [];

      // -----------------------------------------------
      // let localStorageData = {};
      // for (let i = 0; i < sourceList.length; i++) {
      //   const key = sourceList.key(i);
      //   const value = sourceList.getItem(key);
      //   localStorageData[key] = value;
      // }

      // console.log("JSON格式数据", JSON.stringify(sourceList));
      // -----------------------------------------------

      // 相同商品进行数量累加
      chartData = sourceList.reduce((acc, current) => {
        const existing = acc.find((item) => item.shopName === current.shopName);
        if (existing) {
          if (current.tag) {
            existing.tag = current.tag;
          }
          existing.quantity += current.quantity;
        } else {
          acc.push({ ...current });
        }
        return acc;
      }, []);

      console.log(
        "处理后9号和10号的数据",
        JSON.parse(JSON.stringify(chartData))
      );
      // 转化数据格式
      chartData.map((dItem) => {
        switch (dItem.tag) {
          case "打":
            dItem.quantity = (dItem.quantity / 12).toFixed(2) * 1;
            break;
          case "包/6":
            dItem.quantity = (dItem.quantity / 6).toFixed(2) * 1;
            break;
          case "包/10":
            dItem.quantity = (dItem.quantity / 10).toFixed(2) * 1;
            break;
          case "包/15":
            dItem.quantity = (dItem.quantity / 15).toFixed(2) * 1;
            break;
          case "包/20":
            dItem.quantity = (dItem.quantity / 20).toFixed(2) * 1;
            break;
          default:
            break;
        }
      });
      // 排序，高到低
      chartData.sort((a, b) => {
        return b.quantity - a.quantity;
      });
      console.log("统计并进行单位转化后的数据", chartData);
      chartData.forEach((fItem) => {
        xAxisData.push(fItem.shopName);
        seriesData.push(fItem.quantity);
      });
      return chartData;
    },
  },
};
